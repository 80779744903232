<template>
	<div class="tinymce-editor">
		<Editor :id="editorId" v-model="editorValue" :init="editorInit" :disabled="disabled" @onClick="handleClick" />

		<xsk-manage :managehide.sync="imgplugin" @confirm="confirmimg" :multiple="true" :count="100" :selectarr="[]"
			:selectobjs="[]"></xsk-manage>

		<xsk-uploadqn :uploadshow.sync="uploadshow" @confirm="confirmvideo"></xsk-uploadqn>
	</div>
</template>

<script>
	// 引入组件
	import tinymce from 'tinymce/tinymce';
	import Editor from '@tinymce/tinymce-vue';
	// 引入富文本编辑器主题的js和css
	import 'tinymce/themes/silver/theme.min.js';
	import 'tinymce/skins/ui/oxide/skin.min.css';
	import 'tinymce/icons/default/icons.min.js';
	// 扩展插件
	import 'tinymce/plugins/image';
	import 'tinymce/plugins/media';// 插入视频插件
	import 'tinymce/plugins/link';
	import 'tinymce/plugins/code';
	import 'tinymce/plugins/table';
	import 'tinymce/plugins/lists';
	import 'tinymce/plugins/wordcount'; // 字数统计插件
	import 'tinymce/plugins/preview';

	export default {
		name: 'TinymceEditor',
		components: { Editor },
		props: {
			id: {
				type: String,
				default: 'tinymceEditor'
			},
			value: {
				type: String,
				default: ''
			},
			height: {
				type: Number,
				default: 300
			},
			disabled: {
				type: Boolean,
				default: false
			},
			plugins: {
				type: [String, Array],
				default: 'image link'
			},
			toolbar: {
				type: [String, Array],
				default: 'bold italic underline strikethrough | fontsizeselect | alignleft aligncenter alignright alignjustify | link bullist numlist | outdent indent imageUpload | removeformat preview media'
				// default: 'bold italic underline strikethrough | fontsizeselect | alignleft aligncenter alignright alignjustify | link bullist numlist | outdent indent imageUpload videoUpload | removeformat preview media'
			},
			html: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				editorInit: {
					language_url: '/tinymce/langs/zh_CN.js',
					language: 'zh_CN',
					skin_url: '/tinymce/skins/ui/oxide',
					height: this.height,
					plugins: this.plugins,
					toolbar: this.toolbar,
					statusbar: true, // 底部的状态栏
					menubar: false, // 最上方的菜单
					branding: false, // 水印“Powered by TinyMCE”
					fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt",
					images_upload_handler: (blobInfo, success, failure) => {
						this.$emit('handleImgUpload', blobInfo, success, failure);
					},
					setup: (editor) => {//设置自定义功能的按钮
						editor.ui.registry.addButton("imageUpload", {//单个按钮，此处的uploading是该按钮的名称
							tooltip: "上传图片",//鼠标放上去后现在是内容.
							icon: 'image',
							onAction: () => {
								this.imgplugin = true;
								this.editor = editor;
							}
						});
						editor.ui.registry.addButton("videoUpload", {//单个按钮，此处的uploading是该按钮的名称
							tooltip: "上传视频",//鼠标放上去后现在是内容.
							icon: 'embed',
							onAction: () => {
								this.uploadshow = true;
								this.editor = editor;
							}
						});
						editor.on('SetContent', () => {//处理图片自适应
							const images = editor.dom.select('img'); // 获取所有图片元素
							images.forEach(img => {
								// 设置图片宽度和高度，例如：将所有图片宽度设置为100%，高度自适应
								editor.dom.setStyle(img, 'width', '100%'); // 设置宽度为100%
								editor.dom.setStyle(img, 'height', 'auto'); // 高度自适应
							});
						});
					}
				},
				editorId: this.id,
				editorValue: '',
				imgplugin: false,
				uploadshow: false,
				editor: null,
			};
		},
		watch: {
			editorValue(newValue) {
				this.$emit('input', newValue);
			},
			value: {
				handler(val) {
					this.editorValue = JSON.parse(JSON.stringify(val))
				},
				deep: true,
				immediate: true
			},
		},
		mounted() {
			tinymce.init({})
		},
		methods: {
			handleClick(e) {
				this.$emit('onClick', e, tinymce);
			},
			clear() {
				this.editorValue = '';
			},
			sethtml(content) {

			},
			//选择图片
			confirmimg(e) {
				console.log(e)
				let insertHtml = "";
				(e.imgobjs || []).map((item, index) => {
					console.log("item", item)
					insertHtml = insertHtml + "<p><img src=" + item.base_url + "><p>";
				});
				this.editor.execCommand('mceInsertContent', false, insertHtml);
			},
			//选择视频
			confirmvideo(e) {
				let insertHtml = "";
				if (e.videourl) {
					insertHtml = insertHtml + "<div><video src=" + e.videourl + " controls></video> <div>";
				}
				this.editor.execCommand('mceInsertContent', false, insertHtml);
			}
		}
	};
</script>

<style>
	.mce-content-body {
		background: #000000 !important;
	}

	.mce-content-body img {
		width: 100% !important;
		height: auto !important;
	}

	.mce-content-body video {
		width: 100% !important;
		height: auto !important;
	}
</style>