import { render, staticRenderFns } from "./xskSkuM.vue?vue&type=template&id=efe57be4&scoped=true"
import script from "./xskSkuM.vue?vue&type=script&lang=js"
export * from "./xskSkuM.vue?vue&type=script&lang=js"
import style0 from "./xskSkuM.vue?vue&type=style&index=0&id=efe57be4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efe57be4",
  null
  
)

export default component.exports