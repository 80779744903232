<template>
  <div>
    <div class="list">
      <div class="menu-list">
        <div class="logo">
          <div class="logo-left">
            <el-image style="border-radius: 50%;" :src="logo" :class="{ error: error }" fit="fill" @error="handleImg">
            </el-image>
            <div class="name"> 
              {{ time_text }}{{ userinfo.shop?.title || userinfo.nickname}}
            </div>
          </div>
          <img class="show-edit" src="@/assets/icon/edit.png" @click="toEdit" style="width: 13px; height: 13px" />
        </div>
        <div class="menu-item" v-for="(item, index) in meun" :key="index" @click="meunlink(item)">
          <div class="activeBox">
            <a href="javascript:void(0);" class="menu-name" :class="{
              active: item.meta.router_name == $route.meta.router_name,
            }">
             <!-- <svg-icon
                v-show="item.meta.title == '应用'"
                :icon-class="item.meta.icon"
                style="
                  margin-right: 3px;
                  font-size: 16px;
                  position: relative;
                  top: 2px;
                  color: #7a8599;
                "
              ></svg-icon> -->
			  <el-badge v-if="item.meta.title == '订单' && orderNum>0" :value="orderNum" class="item">
			     {{ item.meta.title }}
			  </el-badge>
              <div v-else>{{ item.meta.title }}</div>
            </a>
          </div>
        </div>
      </div>
      <div class="right-setting">
        <img class="icon-img" @click="tips" :src="item.icon" alt="" v-for="(item, index) in imgs" :key="index" />
        <el-dropdown trigger="click" class="menu headernava" @command="handleCommand">
          <div class="el-dropdown-link">
            <img src="@/assets/icon/more.png" alt="more" class="icon-img" />
          </div>
          <el-dropdown-menu slot="dropdown" class="drop-menu">
            <!-- <el-dropdown-item command="changeuser" class="item-font" v-if="userinfo && userinfo.guard_name == 'api'"><i
                class="el-icon-set-up el-icon--left"></i>店铺资料</el-dropdown-item> -->
            <el-dropdown-item command="shopdata" class="item-font"><i
                class="el-icon-goods el-icon--left"></i>店铺资料</el-dropdown-item>
            <el-dropdown-item command="loginout" class="item-font"><i
                class="el-icon-switch-button el-icon--left"></i>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { resetRouter } from "@/router/index.js";
import router from "@/router/index.js";
import { changeroute } from "@/router/routerPermission.js";
import { eltips } from "@/util/util.js";
export default {
  data() {
    return {
      error: false,
      time_text: "上午好！",
      meun: [],
      list: [],
      childindex: 0,
      userinfo: {},
      projectinfo: {},
      loading: true,
      logo: require("@/assets/icon/application.png"),
      menuicon: require("@/assets/icon/icon-menu.png"),
      imgs: [
        {
          id: 0,
          icon: require("@/assets/icon/seach.png"),
          url: "",
        },
        {
          id: 1,
          icon: require("@/assets/icon/qsttion.png"),
          url: "",
        },
        {
          id: 2,
          icon: require("@/assets/icon/tips.png"),
          url: "",
        },
      ],
      ldata: {},
	  orderNum:0
    };
  },
  created() {
	  let _this = this
    this.projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"));
    let data = JSON.parse(sessionStorage.getItem("userinfo"));
    this.userinfo = data;
	let guardName = sessionStorage.getItem("guardName");
	if(guardName == 'shop_user'){
		
		// this.axios.get("/api/shop/basic/shop-info", {}).then((res) => {
		// 	_this.logo = res.data.config.site_logo_url
		// })
		this.axios.get("/api/shop/basic/shop-info", {}).then((res) => {
			console.log("res.data1111",res.data)
			_this.logo = res.data.image_url
		})
	}else{
		this.axios.get("/api/manage/basic/config-index", {}).then((res) => {
			console.log("res.data.site_logo",res.data.site_logo)
			_this.logo = res.data.site_logo_url
		})
		// this.axios.get("/api/shop/basic/shop-info", {}).then((res) => {
		// 	_this.logo = res.data.image_url
		// })
	}
    let routes = JSON.parse(sessionStorage.getItem("routestext"));
    routes.forEach((item) => {
      if (item.meta.layout == 1) {
        this.meun.push(item);
      }
    });
    this.children_route();
    this.gettime();
  },
  mounted() {
    setTimeout(() => {
      console.log("meun", this.meun);
    }, 2000);
    // this.getInfo();
    // this.getSetting();
  },
  watch: {
    $route: function (newflag, oldflag) {
      this.children_route();
    },
  },
  methods: {
    //根据时间改变提示语
    gettime() {
      let time = new Date().getHours();
      if (time < 6) {
        this.time_text = "夜深了！";
      } else if (time < 12) {
        this.time_text = "早上好！";
      } else if (time < 14) {
        this.time_text = "中午好！";
      } else if (time < 18) {
        this.time_text = "下午好！";
      } else if (time < 23) {
        this.time_text = "晚上好！";
      } else {
        this.time_text = "夜深了！";
      }
    },
    //获取设置
    getSetting() {
      this.loading = true;
      this.axios.get("/api/retail/admin/settings/basic").then((res) => {
        this.loading = false;
        if (res.code == 200 && res.data) {
          this.ldata = res.data;
          console.log(
            "%c [ this.ldata ]-140",
            "font-size:13px; background:#8dd2d3; color:#d1ffff;",
            this.ldata
          );
        } else {
          eltips(res.msg, "error");
        }
      });
    },
    //tips
    tips() {
      this.$message("功能开发中，敬请期待！");
    },
    //跳转编辑
    toEdit() {
      this.$router.push({
        path: "/shopping/setting/shoppingsetting/basics",
      });
    },
    handleImg() {
      this.error = true;
      this.logo = require("@/assets/icon/application.png");
    },
    //获取信息
    getInfo() {
    },
    calclayout(arr) {
      let count = 0;
      arr.forEach((item) => {
        if (item.layout == 1) {
          count++;
        }
      });
      if (count > 1) {
        return true;
      } else {
        return false;
      }
    },
    //监控子路由
    children_route() {
      let that = this;
      let routes = JSON.parse(sessionStorage.getItem("routestext"));
      if (this.$route.matched.length > 1) {
        routes.forEach((item) => {
          if (item.meta.title == that.$route.matched[0].meta.title) {
            that.list = [];
            if (item.children && item.children.length > 0) {
              item.children.forEach((child) => {
                if (child.meta.layout == 1) {
                  that.list.push(child);
                }
              });
            }
          }
        });
        if (that.list.length > 0) {
          this.$store.commit("changeasideWidth", 50);
        } else {
          this.$store.commit("changeasideWidth", 0);
        }
      } else {
        this.list = [];
        this.$store.commit("changeasideWidth", 0);
      }
    },
    //跳转
    meunlink(item) {
      console.log(item, "一级菜单跳转");
      if (item.path) {
        if (item.path != this.$route.path) {
          let userinfo = JSON.parse(sessionStorage.getItem("userinfo")) || "";
          let unitid = userinfo.unitid || "";
		  // sessionStorage.getItem("is_plugin")
		  if(sessionStorage.getItem("is_plugin")){
			  
			  
			 let ol = JSON.parse(sessionStorage.getItem("routesOld"))
			 if(!ol){
				ol = JSON.parse(sessionStorage.getItem("routes") )
			 }
			  resetRouter();
			  router.addRoutes(changeroute(ol));
			  sessionStorage.setItem("routes", JSON.stringify(ol));
          }

          this.$router.push({
            path: item.path,
          });
          this.$store.commit("changeasideWidth", 0);
        }
      } else {
        if (item.children[0].path != this.$route.path) {
          for (let i = 0; i < item.children.length; i++) {
            let child = item.children[i];
            if (child.meta.layout == 1) {
              if (child.children) {
                this.childindex = 0;
                if (child.children[0].path == this.$route.path) {
                  return;
                }

                if (child.children[0].meta.props) {
                  this.$router.push({
                    path: child.children[0].path,
                    query: {
                      props: child.children[0].meta.props,
                    },
                  });
                  return;
                } else {
                  this.$router.push({
                    path: child.children[0].path,
                  });
                  return;
                }
              } else {
                this.childindex = -1;
                if (child.meta.props) {
                  this.$router.push({
                    path: child.path,
                    query: {
                      props: child.meta.props,
                    },
                  });
                } else {
                  this.$router.push({
                    path: child.path,
                  });
                }
                this.$store.commit("changeasideWidth", 50);
                return;
              }
            }
          }
          this.$store.commit("changeasideWidth", 0);
        }
      }
    },
    //二级菜单跳转
    navpath(item, index) {
      if (item.children) {
        if (this.childindex == index) {
          this.childindex = -1;
          // return
        } else {
          this.childindex = index;
        }
        // if(item.children[0].path!=this.$route.path){
        // 	if(item.children[0].meta.props){
        // 		this.$router.push({path: item.children[0].path,query:{props:item.children[0].meta.props}})
        // 	}else{
        // 		this.$router.push({path: item.children[0].path})
        // 	}
        // }
      } else {
        this.childindex = -1;

        if (item.path && item.path != this.$route.path) {
          if (item.meta.props) {
            this.$router.push({
              path: item.path,
              query: {
                props: item.meta.props,
              },
            });
          } else {
            this.$router.push({
              path: item.path,
            });
          }
        }
      }
    },
    //三级菜单跳转
    threepath(item) {
      if (item.path && item.path != this.$route.path) {
        if (item.meta.props) {
          this.$router.push({
            path: item.path,
            query: {
              props: item.meta.props,
            },
          });
        } else {
          this.$router.push({
            path: item.path,
          });
        }
      }
    },
    handleCommand(command) {
		console.log("command",command)
		if (command == "loginout") {
			this.axios.get("/api/manage/basic/logout").then((res) => {
				sessionStorage.removeItem("projectinfo");
				sessionStorage.removeItem("routestext");
				sessionStorage.removeItem("routes");
				sessionStorage.removeItem("userinfo");
				sessionStorage.removeItem("token");
				this.$router.push({
					path: sessionStorage.getItem("loginPage")
				});
			});
		}
		if (command == "shopdata") {
			let guardName = sessionStorage.getItem("guardName");
			if(guardName == 'shop_user'){
				this.$router.push({
					path: '/common/set/site'
				});
			}else{
				this.$router.push({
					path: '/admin/account/index'
				});
			}
			
		}
		
    },
  },
};
</script>
<style lang="scss" scoped>
	// $mainBluecolor:#006AFF;
.list {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 12px;
  box-sizing: border-box;

  .menu-item {
    min-width: 64px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #b6babf;
    cursor: pointer;
    text-align: center;
    border-bottom: 3px solid transparent;
    box-sizing: border-box;
  }

  .activeBox {
    padding: 0 16px;
	position: relative;
  }

  .active {
    color: #2d8cf0 !important;
    // border-bottom: 3px solid #2d8cf0 !important;
    // border-bottom-color: #2d8cf0 !important;
    box-sizing: border-box;
    font-weight: bold;
	position: relative;
	&::after{
		content: '';
		bottom: -3px;
		height:3px ;
		background-color: #2d8cf0;
		width: 100%;
		position: absolute;
		border-radius: 4px;
	}
  }
}

.logImage {
  width: 70px;
  height: 26px;
  margin: 0 auto 13px;
}

.logbox {
  width: 70px;
  height: 26px;
  margin: 0 auto 13px;
  background-size: 100% 100%;
}

.logo {
  min-width: 232px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  // background-color: rgba(255, 255, 255, 0.2);

  .logo-left {
    .error {
      border-radius: 4px;
      background-color: #ffffff;
    }

    .el-image,
    img {
      width: 30px;
      height: 30px;
    }
  }

  .logo-left {
    display: flex;
    box-sizing: border-box;
  }

  .name {
    color: #b6babf;
    font-weight: 600;
    font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji,
      segoe ui emoji, Segoe UI Symbol, noto color emoji;
    font-size: 15px;
    padding-left: 10px;
    line-height: 30px;
  }
}

// .logo:hover {
//   background-color: rgba(255, 255, 255, 0.2);
//   color: #fff !important;
// }
// .logo:hover .show-edit {
//   display: block;
// }
.show-edit {
  display: none;
  cursor: pointer;
}

.menu-list {
  display: flex;
  align-items: center;
  height: 100%;
}

.icon-img {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  cursor: pointer;
}

.item-font {
  font-size: 15px;
  padding: 15px 20px;
}

.right-setting {
  min-width: 128px;
}

.menu-name {
  // color: #8a8c99 !important;
  color: #ffffff !important;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 3px solid transparent;
  font-size: 15px;
  // font-weight: bold;
}

.menu-name:hover {
  // border-bottom: 3px solid #2d8cf0 !important;
  // border-bottom-color: #2d8cf0 !important;
  color: #2d8cf0 !important;
  &::after{
	  background-color: #2d8cf0 !important;
  }
}
</style>