<template>
  <div class="content-box"
    :style="{'padding':pd10, 'position': 'absolute',top: '58px',left: 'calc('+left+'px)',width: 'calc(100% - ' + left + 'px)'}">
    <recom></recom>
    <!-- <div class="footbox">
      {{ userinfo.admin_bottom_text || (projectinfo ? projectinfo.technical_support : "") || `Powered by 盛夏光年信息技术开发有限公司 &copy; 2015-${ year > 2015 ? year : 2020 }` }}
    </div> -->
  </div>
</template>
<script>
  import recom from "./recom.vue";
  export default {
    name: "contentBox",
    components: {
      recom
    },
    props: {
      left: {
        type: Number,
        default: 160
      }
    },
    data() {
      return {
		  pd10:'10px',
        year: new Date().getFullYear(),
        userinfo: {
          admin_bottom_text: ''
        },
        projectinfo: {
          technical_support: ''
        },
      };
    },
    created() {
      this.projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"));
      this.userinfo = JSON.parse(sessionStorage.getItem("userinfo")) || "";
	  
    },
	watch: {
	    // 监听路由对象中的变化
	    '$route': {
	      handler: function (to, from) {
			  if(to.path == '/haowu/manager/overview'){
				  this.pd10 = 0
			  }else{
				  this.pd10 = '10px'
			  }
	        // to 和 from 分别代表到达的路由和离开的路由
	        // 在这里可以根据路由变化执行相应的逻辑
	      },
	      // 如果需要在路由变化时立即响应，可以设置immediate为true
	      immediate: true
	    }
	}
  };
</script>
<style lang="scss" scoped>
  .content-box {
    // width: calc(100vw - 160px);
    height: calc(100vh - 58px);
    background: rgb(245, 247, 250);

    // padding: 10px;
    box-sizing: border-box;

    .footbox {
      margin-top: 10px;
      width: 100%;
      text-align: center;
      font-size: 12px;
      line-height: 30px;
      color: #b8b9bd;
    }
  }
</style>