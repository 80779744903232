import { render, staticRenderFns } from "./notice.vue?vue&type=template&id=4860e76e&scoped=true"
import script from "./notice.vue?vue&type=script&lang=js"
export * from "./notice.vue?vue&type=script&lang=js"
import style0 from "./notice.vue?vue&type=style&index=0&id=4860e76e&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4860e76e",
  null
  
)

export default component.exports